/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import SubscribeForm from '../SubscribeForm';

export const BlogListSubscribe = ({ className }) => {
  return (
    <div className={className}>
      <div className="header">
        Subscribe to our Newsletter!
      </div>
      <div className="body">
        Subscribe to our mailing list and receive our Weekly Resource Digest!
      </div>
      <div className="footer">
        <SubscribeForm />
      </div>
    </div>
  )
};

export default styled(BlogListSubscribe)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
  background: #005F64;
  color: #FFFFFF;
  position: relative;
  padding: 8rem 2rem;
  /* margin: 2rem 0 0; */
  /* width: 100vw !important; */
  .header {
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;
  }
  
  .body {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    padding: 20px 10px;
    max-width: 488px;
    margin: 0 auto;

  }
  .footer {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    #mc-embedded-subscribe {
      border-radius: 23px;
      border: none;
      font-size: 16px;
      line-height: 24px;
      background: #FFFFFF !important;
      color: #005F64;
      &:focus {
        outline: none;
      }  
    }
    #mce-responses {
      display: none;
    }
    #mc_embed_signup_scroll {
      display: flex;
      justify-content: center;
    }
  }

`;
