/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Card, Image } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from 'gatsby';

export const BlogCard = props => {
  const { post } = props;
  return (
    <Card className={cx(props.className)}>
      <Card.Content className="blog-content"
        as="a"
        href={_.get(post, 'fields.slug')}      
      >
        <div className="cover">
          <Image
            className="thumbnail"
            src={_.get(post, 'frontmatter.image.childImageSharp.fluid.src')}
          />
        </div>
        <div className="footer">
          <div className="taglist">
            {_.castArray(_.get(post, 'frontmatter.category', [])).map(tag => (
              <div className="tag" key={tag + `tag`}>
                <Link to={`/categories/${_.kebabCase(tag)}/`}>{tag}</Link>
              </div>
            ))}
            {_.castArray(_.get(post, 'frontmatter.tags', [])).map(tag => (
              <div className="tag" key={tag + `tag`}>
                <Link to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
              </div>
            ))}
          </div>
          <div className="line-title">{_.get(post, 'frontmatter.title', '')}</div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default styled(BlogCard)`
  .info {
    font-size: ${props => props.theme.sizeSmallText};
    line-height: 25px;
  }
  .cover {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 120%;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding-top: 80%;
    }
    .thumbnail {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      > img {
        max-height: 100% !important;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .coverFooter {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }
  .footer {
    padding: 17px 20px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 1rem;
    }
    @media (min-width: ${({ theme }) => theme.notMobile}) {
      height: 160px;
      max-height: 160px;
    }
    .line-title {
      color: #424242 !important;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin: 3px;
      @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 3px 10px 20px;
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        margin: 3px 0px 20px;
        font-size: 16px;
      }

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .taglist {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 1) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .tag {
        display: inline-block;
        a {
          color: ${({ theme }) => theme.altPink} !important;
          line-height: 24px;
          font-size: 12px;
          font-weight: 600;
          @media (max-width: ${({ theme }) => theme.tablet}) {
            font-size: 12px;
          }
          @media (max-width: ${({ theme }) => theme.mobile}) {
            font-size: 10px;
            font-weight: 500;
          }
          text-transform: uppercase;
          border-radius: 23px;
          padding: 4px 16px;
        }
        margin: 0px 4px 4px 0px;
        &:nth-child(3n+1) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #005F64;
          }
        }
        &:nth-child(3n+2) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #CD5B49;
          }
        }
        &:nth-child(3n) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #8A7441;
          }
        }
      }
    }
  }
  height: auto;
  overflow: hidden;
  margin: 20px auto !important;
  border-radius: 15px !important;
  & .content {
    padding: 0px !important;
    position: relative;
  }
  box-shadow: 0px 3px 6px #00000029 !important;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    box-shadow: 0px 3px 6px #00000029 !important;
  }
`;
