/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';

import BlogCard1 from './BlogCard1';

export const BlogList = props => {
  const { posts, title, moreLink } = props;
  return (
    <div className={cx(props.className)}>
      {
        !!title &&
        <div className="header">
          <div className="list-title">{title}</div>
          { moreLink && <a className="link-more" href={moreLink}>{'See All >'}</a>}
        </div>
      }
      <Grid >
        {
          posts.map(({ node: post }) => {
            return (
              <Grid.Column key={post.id} mobile={16} tablet={8} computer={5} className="col grid">
                <BlogCard1 post={post} />
              </Grid.Column>
            )
          })
        }
      </Grid>
    </div>
  )
};

export default styled(BlogList)`
  max-width: 1127px !important;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 0px !important;
  }

  @media (min-width: ${({ theme }) => theme.notMobile}) and (max-width: ${({ theme }) => theme.tablet}) {
    padding: 0 76px !important;
  }
  
  .header {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      display: none;
    }
    display: flex;
    justify-content: space-between;
    .link-more {
      font-size: 16px;
      line-height: 22px;
      color: #424242 !important;
      padding: 0 1rem;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none;
      }

    }
  }
  .list-title {
    padding: 0 14px;
    color: #424242;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
  > .col.column.grid {
    width: 33.3% !important;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;
