import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { Link } from 'gatsby';
import _ from 'lodash';

const mapTags = (allTagsRemark) => {
  const tagsByEdges = _.map(_.castArray(_.get(allTagsRemark, 'edges', [])), 'node.frontmatter.tags');
  const tagsList = {};
  tagsByEdges.forEach((tagsByEdge) => {
    _.castArray(tagsByEdge || []).forEach(item => {
      const tag = _.upperCase(item);
      if(!tagsList[tag]) {
        tagsList[tag] = 1;
      } else {
        tagsList[tag] = tagsList[tag] + 1;
      }
    })
  });
  return _.takeRight(_.sortBy(_.toPairs(tagsList), ([tag, count]) => count), 5).map(([tag]) => tag);
};

export default styled(props => {
  const { className, allTagsRemark, submenus } = props;
  let { tags } = props; 
  if(submenus) {
    tags = _.castArray(submenus || []).map(({ label }) => label)
  } else if(allTagsRemark) {
    tags = mapTags(allTagsRemark);
  }
  return (
    <div className={cx(className, 'bl-tags')}>
      <div className="taglist">
        {_.castArray(tags || []).map((tag, index) => (
          <div className="tag" key={tag + `tag`}>
            <Link
              to={_.get(submenus, [index, 'linkURL']) || `/categories/${_.kebabCase(tag)}/`}
              getProps={({ isPartiallyCurrent, ...rest }) => {
                return isPartiallyCurrent
                ? { className: 'active' }
                : { }
              }}
            >{tag}</Link>
          </div>
        ))}
      </div>
    </div>
  );
})`
  max-width: 1127px !important;
  margin: auto;
  padding: ${({ theme }) => `0 ${theme.p1}`};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ theme }) => `0 ${theme.ps1}`};
    display: none;
  }
  line-height: 40px;
  .taglist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 0 20px;
    }
    .tag {
      a {
        color: ${({ theme }) => theme.primaryBlack} !important;
        line-height: 24px;
        font-size: 16px;
        @media (max-width: ${({ theme }) => theme.tablet}) {
          font-size: 14px;
        }
        text-transform: uppercase;
        &.active {
          text-decoration: underline;
        }
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        &:not(:first-child) {
          &:before {
            content: "•";
            color: ${({ theme }) => theme.primaryBlack} !important;
            margin: 0 4px 0 0;
          }          
        }
        padding: 0 4px;
      }
      padding: 0 24px;
    }
  }
  .block {
    padding-left: ${({ theme }) => theme.p1};
    border-left: ${({ theme }) => theme.divider};
    .quote {
      color: ${({ theme }) => theme.altPink};
      font-size: 50px;
      line-height: 1.2em;
      padding: ${({ theme }) => theme.p1} 0px;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 30px;
      }
    }
    .caption {
      color: ${({ theme }) => theme.primaryBlack};
      font-size: 14px;
      opacity: 0.7;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 11px;
      }
    }
  }
`;
