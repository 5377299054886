import React from 'react';
import styled from 'styled-components';

const FormWrapper = styled.div`
  font-family: Poppins;
  #mc_embed_signup {
    background: transparent !important;
  }
  #mce-EMAIL {
    border-radius: 19px !important;
    border: 1px solid white !important;
    caret-color: white;
    &:focus {
      outline: none;
    }
    &::placeholder { 
      color: white;
      opacity: 0.8;
    }
    &::-ms-input-placeholder {
      color: white;
      opacity: 0.8;
    }
    padding: 8px 10px !important;
    background: transparent;
    font-family: Poppins;
  }
  .indicates-required {
    display: none;
  }
  .mc-field-group {
    width: 240px !important;
    float: left;
    label {
      display: none !important;
    }
    margin-right: 20px;
  }
  .clear {
    clear: none !important;
    .button {
      border-radius: 19px !important;
      background: #005F64 !important;
      font-family: Poppins;
    }
  }
`;

const signupHtml = `<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
  #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
  /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://app.us19.list-manage.com/subscribe/post?u=7c623c4916e3b4c6839e07a36&amp;id=9ab706cd99" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
  <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
  <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Your email">
</div>
  <div id="mce-responses" class="clear">
    <div class="response" id="mce-error-response" style="display:none"></div>
    <div class="response" id="mce-success-response" style="display:none"></div>
  </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_7c623c4916e3b4c6839e07a36_9ab706cd99" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='MMERGE7';ftypes[7]='zip';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->`;

export const SubcribeForm = () => {
  return (
    <FormWrapper 
      dangerouslySetInnerHTML={{
        __html: signupHtml,
      }}
    />
  );
};

export default SubcribeForm;
