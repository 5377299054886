/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

export const BlogListWelcome = ({ className }) => {
  return (
    <div className={className}>
      <div className="header">
        Welcome to the BestFit Blog
      </div>
      <div className="body">
        To get started, click on any of our articles or click the button below to learn more about us.
      </div>
      <div className="footer">
        
        <a href="https://best-fit.app/about">Learn More</a>
      </div>
    </div>
  )
};

export default styled(BlogListWelcome)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
  background: #CD5B49;
  color: #FFFFFF;
  position: relative;
  padding: 8rem 2rem;
  margin: 2rem 0;
  .header {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
  }
  
  .body {
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    font-weight: bold;
    padding: 20px 10px;
    max-width: 960px;
    margin: 0 auto;
  }
  .footer {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    a {
      border-radius: 23px;
      border: none;
      padding: 12px 36px;
      font-size: 16px;
      line-height: 24px;
      background: #FFFFFF;
      color: #CD5B49;
    }

  }

`;
