import styled from 'styled-components';

const PageHeader = styled.h1`
  color: #CD5B49;
  font-size: 50px;
  text-align: center;
  line-height: 65px;
  font-weight: bold;
  max-width: 1127px !important;
  margin: auto !important;
  a {
    color: #CD5B49 !important;
  }
`;

export default PageHeader;
