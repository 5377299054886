/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import BlogCard from './BlogCard2';
import BlogList3 from './BlogList3';

export const BlogList2 = props => {
  const { posts } = props;
  const [ featuredPost, ...rest ] = posts;
  return (
    <Grid className={cx(props.className)} centered>
      <Grid.Column mobile={16} tablet={16} computer={10} className="col1 grid">
        <BlogCard post={_.get(featuredPost, 'node')} />
      </Grid.Column>
      <Grid.Column mobile={16} tablet={6} computer={6} className="col2 grid">
        <BlogList3 posts={rest} />
      </Grid.Column>
    </Grid>
  )
};

export default styled(BlogList2)`
  max-width: 1127px !important;
  margin: auto !important;

  @media (min-width: ${({ theme }) => theme.notMobile}) and (max-width: ${({ theme }) => theme.tablet}) {
    padding: 0 76px !important;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 0px !important;
  }
  > .col1.column.grid {
    width: 66.6% !important;
    @media (max-width: ${({ theme }) => theme.tablet}) {
      width: 100% !important;
    }
  }
  > .col2.column.grid {
    width: 33.3% !important;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
      max-height: 90vh;
      overflow-y: auto;
    }
  }
`;
