const _ = require('lodash');
const moment = require('moment');

const cleanObject = (obj, cleaner) =>
  _.omitBy(
    obj,
    val =>
      _.isNil(val) ||
      (_.isArray(val) && val.length === 0) ||
      (_.isFunction(cleaner) && cleaner(val)),
  );
exports.cleanObject = cleanObject;

/**
 * convert query object of router to a query object tree
 * @param {*} query query object from router
 */
const queryToObject = query => {
  const rtn = {};
  _.forIn(query, (value, key) => {
    _.set(rtn, key, value);
  });
  return rtn;
};
exports.queryToObject = queryToObject;

const visit = (obj, paths, acc) => {
  _.forIn(obj, (value, key) => {
    if (_.isPlainObject(value)) {
      visit(value, [...paths, key], acc);
    } else if (
      _.isString(value) ||
      _.isNumber(value) ||
      _.isArray(value) ||
      _.isNil(value)
    ) {
      acc[[...paths, key].join('.')] = value;
    }
  });
};

/**
 * convert query object tree back to router query object
 * @param {*} obj the query object tree
 */
const objectToQuery = obj => {
  const query = {};
  visit(obj, [], query);
  return query;
};

exports.objectToQuery = objectToQuery;

const pluralText = (count, [singleTxt, pluralTxt]) => {
  return count === 1 ? singleTxt : pluralTxt;
};
exports.pluralText = pluralText;

const airtableNodeSlug = node => {
  const publishDate = moment(_.get(node, 'data.date'));
  const title = _.get(node, 'data.title');
  return `/${publishDate.format("YYYY/MM/DD")}/${_.kebabCase(title)}/`;
}

exports.airtableNodeSlug = airtableNodeSlug;

const formatAirtableNode = (node) => {
  return {
    node: {
      excerpt: _.get(node, 'data.description'),
      fields: {
        slug: airtableNodeSlug(node),
      },
      frontmatter: {
        category: _.get(node, 'data.category', []),
        date: moment(_.get(node, 'data.date')).format('MMMM DD, YYYY'),
        image: {
          childImageSharp: {
            fluid: {
              src: _.get(node, 'data.image.0.url')
            }
          }
        },
        tags: _.take(_.get(node, 'data.tags', []), 1), // 1 tag + category
        templateKey: _.get(node, 'data.templateKey', ''),
        title: _.get(node, 'data.title', ''),
      },
      id: _.get(node, 'id')
    }
  }
}
exports.formatAirtableNode = formatAirtableNode;

const formatAirtableSubMenu = (items) => {
  if(!items) return null;
  return _.map(items, item => ({
    label: _.get(item, 'data.Title'),
    linkType: _.get(item, 'data.SubTitle', 'internal'),
    linkURL: _.get(item, 'data.SubTitle2', '/tags'),
  }))
}
exports.formatAirtableSubMenu = formatAirtableSubMenu;

module.exports = {
  cleanObject,
  queryToObject,
  objectToQuery,
  pluralText,
  airtableNodeSlug,
  formatAirtableNode,
  formatAirtableSubMenu,
};
