/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Item } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import BlogCard3 from './BlogCard3';

export const BlogList3 = props => {
  const { posts } = props;
  return (
    <div className={cx(props.className)}>
      <div className="title">Popular Articles</div>
      <Item.Group divided unstackable>
        {
          _.take(posts, 4).map(({ node: post }, index) => {
            return (
              <BlogCard3 key={`${post.id}-${index}`} post={post} />
            )
          })
        }
      </Item.Group>

    </div>
  )
};

export default styled(BlogList3)`
  .title {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    font-weight: 600;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 16px;
    }
  }
  @media (min-width: ${({ theme }) => theme.notMobile}) and (max-width: ${({ theme }) => theme.tablet}) {
    display: none;
  }
`;
