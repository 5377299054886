import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash';

import BlogLayout from '../components/BlogLayout'
import PageHeader from '../components/styles/PageHeader';
import PageContainer from '../components/styles/PageContainer'
import BlogList1 from '../components/BlogList/BlogList1';
import BlogList2 from '../components/BlogList/BlogList2';
import BlogListWelcome from '../components/BlogList/BlogListWelcome';
import BlogListSubscribe from '../components/BlogList/BlogListSubscribe';
// import BlogList4 from '../components/BlogList/BlogList4';
import TagNav from '../components/TagNav';
import '../graphql/blogFragment';
import { formatAirtableNode, formatAirtableSubMenu } from '../lib/utils';


const BlogIndex = ({ data }) => {
  // const { edges: posts } = data.allMarkdownRemark;

  const posts = _.castArray(_.get(data, 'posts.nodes', [])).map(formatAirtableNode);

  // group post by category
  const postGroups = {};
  _.map(posts, (post) => {
    const cats = _.get(post, 'node.frontmatter.category') || [];
    cats.map(cat => {
      postGroups[cat] = postGroups[cat] ? postGroups[cat] : [];
      postGroups[cat].push(post);
      return cat;
    });
  });

  const highlightedPost = _.castArray(_.get(data, 'highlightedPost.nodes', [])).map(formatAirtableNode);
  const submenus = formatAirtableSubMenu(_.get(data, 'submenus.nodes')) || _.get(data, 'submenuPage.frontmatter.menuItems');

  let catCount = 0;
  return (
    <BlogLayout>
      <section className="section">
        <PageContainer className="container">
          <div className="content">
            <PageHeader>
              <a href="/">Blog</a>
            </PageHeader>
            <TagNav allTagsRemark={data.allTagsRemark} submenus={submenus}/>
            <BlogList2 posts={[...highlightedPost, ...posts]} />
            {
              _.map(postGroups, (posts, title) => {
                catCount = catCount + 1;
                return (
                  <React.Fragment key={title} >
                    <BlogList1 posts={_.take(posts, 3)} title={title} moreLink={`/categories/${_.kebabCase(title)}`}/>
                    { catCount === 2 && <BlogListWelcome />}
                  </React.Fragment>
                )
              })
            }
            {/* <BlogList4 posts={posts} /> */}
            <BlogListSubscribe />
          </div>
        </PageContainer>
      </section>
    </BlogLayout>
  )
}

BlogIndex.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndex {
    posts: allAirtable(
      filter: {
        table: {in: ["Blog", "News"]},
        data: {
          status: {eq: "published"},
          title: {ne: null},
        }
      },
      sort: {
        order: DESC, fields: data___date_created
      }
    ) {
      nodes {
        ...blogFragment
      }
    }
    highlightedPost: allAirtable(
      filter: {
        table: {in: ["Blog", "News"]},
        data: {
          status: {eq: "published"}
          highlighted: {eq: true}
        }
      },
      sort: {
        order: DESC, fields: data___last_updated
      }
    ) {
      nodes {
        ...blogFragment
      }
    }

    submenus: allAirtable(filter: {
      data: {Segment: {regex: "/^submenu/"}}
      table: {in: ["Website"]},
    }, sort: {fields: data___Segment}) {
      nodes {
        data {
          Segment
          Title
          SubTitle
          SubTitle2
        }
      }
    }
    submenuPage: markdownRemark(frontmatter: { templateKey: { eq: "submenu" } }) {
      id
      frontmatter {
        menuItems {
          label
          linkType
          linkURL
        }
      }
    }
    allTagsRemark: allMarkdownRemark(
      limit: 1000
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            tags
            category
          }
        }
      }
    }
  }
`
