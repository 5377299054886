/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Item } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from 'gatsby';

export const BlogCard3 = props => {
  const { post } = props;
  const href = _.get(post, 'fields.slug');
  return (
    <Item className={cx(props.className)}
      as="a"
      href={href}
    >
      <Item.Image
        size='tiny'
        className="thumbnail"
        src={_.get(post, 'frontmatter.image.childImageSharp.fluid.src')}
      />
      <Item.Content className="blog-content">
        <div className="tags">
          {_.castArray(_.get(post, 'frontmatter.category', [])).map(tag => (
              <div className="tag" key={tag + `tag`}>
                <Link to={`/categories/${_.kebabCase(tag)}/`}>{tag}</Link>
              </div>
            ))}
          {_.castArray(_.get(post, 'frontmatter.tags', [])).map((tag, index) => (
            <div className={cx("tag", `type-${index % 2}`)} key={tag + `tag`}>
              <Link to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
            </div>
          ))}
        </div>
        <Item.Description className="line-title" as="a" href={href}>
          {_.get(post, 'frontmatter.title', '')}
        </Item.Description>
      </Item.Content>
    </Item>
  );
};

export default styled(BlogCard3)`
  .thumbnail {

    width: 94px !important;
    max-width: 94px !important;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 80px !important;
      max-width: 80px !important;
    }
    img {
      width: 94px !important;
      height: 94px !important;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 80px !important;
        height: 80px !important;
      }
      object-fit: cover;
      border-radius: 8px !important;
    }
  }
  .blog-content {
    padding: 7px;
    .line-title {
      color: #424242 !important;
      font-size: 16px !important;
      line-height: 22px !important;
      padding: 0px 8px 0 16px;
      margin-top: 0px !important;
      display: block;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 3) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .tags {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      padding: 0px 8px 0 16px;
      .tag {
        display: inline-block;
        margin: 0px 4px 4px 0px;
        a {
          line-height: 24px;
          font-size: 12px;
          font-weight: 400;
          @media (max-width: ${({ theme }) => theme.tablet}) {
            font-size: 12px;
          }
          @media (max-width: ${({ theme }) => theme.mobile}) {
            font-size: 10px;
          }
          text-transform: uppercase;
          border-radius: 23px;
          padding: 4px 16px;
        }
        &:nth-child(3n+1) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #005F64;
          }
        }
        &:nth-child(3n+2) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #CD5B49;
          }
        }
        &:nth-child(3n) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #8A7441;
          }
        }
      }
    }
  }
  height: auto;
  overflow: hidden;
  margin: 0 auto 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  & .content {
    padding: 0px !important;
    position: relative;
  }
`;
