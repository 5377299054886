/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Card, Image } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';
import { Link } from 'gatsby';

export const BlogCard2 = props => {
  const { post } = props;
  return (
    <Card className={cx(props.className)}>
      <Card.Content className="blog-content"
        as="a"
        href={_.get(post, 'fields.slug')}
      >
        <div className="cover">
          <Image
            className="thumbnail"
            src={_.get(post, 'frontmatter.image.childImageSharp.fluid.src')}
          />
        </div>
        <div className="footer">
          <div className="taglist">
            {_.castArray(_.get(post, 'frontmatter.category', [])).map(tag => (
              <div className="tag" key={tag + `tag`}>
                <Link to={`/categories/${_.kebabCase(tag)}/`}>{tag}</Link>
              </div>
            ))}
            {_.castArray(_.get(post, 'frontmatter.tags', [])).map(tag => (
              <div className="tag" key={tag + `tag`}>
                <Link to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
              </div>
            ))}
          </div>
          <div className="line-title">{_.get(post, 'frontmatter.title', '')}</div>
          <div className="line-excerpt">{_.get(post, 'excerpt', '')}</div>
          <div className="line-readmore" >
            <a href={_.get(post, 'fields.slug')}>Read article</a>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

export default styled(BlogCard2)`
  .info {
    font-size: ${props => props.theme.sizeSmallText};
    line-height: 25px;
  }
  .cover {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 80%;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding-top: 80%;
    }
    .thumbnail {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: block;
      max-height: 100% !important;
      width: 100%;
      object-fit: contain;
    }
    .coverFooter {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }
  }
  .footer {
    padding: 7px;
    .line-title {
      color: #424242 !important;
      font-size: 30px;
      line-height: 40px;
      margin: 3px 20px 20px;
      font-weight: bold;
      @media (max-width: ${({ theme }) => theme.tablet}) {
        margin: 3px 10px 20px;
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 16px;
        line-height: 18px;
        text-align: left;
      }
      text-align: center;
    }
    .line-excerpt {
      color: #424242 !important;
      font-size: 16px;
      line-height: 24px;
      padding: 3px 50px;
      text-align: center;
      margin: 20px auto;
      @media (max-width: ${({ theme }) => theme.mobile}) {
        text-align: left;
        padding: 10px;
        margin: 0;
      }
    }
    .line-readmore {
      text-align: center;
      margin: 24px auto;
      a {
        border-radius: 28px;
        border: 2px solid #005F64;
        padding: 14px 36px;
        font-size: 20px;
        line-height: 35px;
        color: #005F64;
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none;
      }
    }
    .taglist {
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none !important;
      }
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      margin: 20px auto 10px;
      .tag {
        display: inline-block;
        margin: 0px 4px 4px 0px;
        a {
          color: ${({ theme }) => theme.altPink} !important;
          line-height: 24px;
          font-size: 12px;
          font-weight: 400;
          @media (max-width: ${({ theme }) => theme.tablet}) {
            font-size: 12px;
          }
          @media (max-width: ${({ theme }) => theme.mobile}) {
            font-size: 10px;
            font-weight: 500;
          }
          text-transform: uppercase;
          border-radius: 23px;
          padding: 4px 16px;
        }
        &:nth-child(3n+1) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #005F64;
          }
        }
        &:nth-child(3n+2) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #CD5B49;
          }
        }
        &:nth-child(3n) {
          a {
            color: ${({ theme }) => theme.primaryWhite} !important;
            background: #8A7441;
          }
        }

      }
    }
  }
  height: auto;
  overflow: hidden;
  margin: 20px auto !important;
  border-radius: 15px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  & .content {
    padding: 0px !important;
    position: relative;
  }
  width: 100% !important;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    box-shadow: 0px 3px 6px #00000029 !important;
  }
`;
